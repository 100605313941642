<template>
	<div>マイページ</div>
</template>

<script>

export default {
	components: {
	},
	computed: {
	},
	data() {
		return {
		};
	},
};
</script>
